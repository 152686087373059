// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-app-background: #F6F7F8;
  --ion-color-dark-label: #424443;
  --ion-color-dark-balck-label: #3A3A3C;
  
  --ion-color-primary: #4778dc;
  --ion-color-primary-rgb: 71, 120, 220;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #EF6725;//#e0633c;
  --ion-color-secondary-rgb: 224, 99, 60;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #d94c1d;
  --ion-color-secondary-tint: #f3693c;
  --ion-color-secondary-label: #F1582D;
  

  /** tertiary **/
  --ion-color-tertiary: red;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ee1225;
  --ion-color-tertiary-tint: #ff6363;

  /** success **/
  --ion-color-success: #43aa4c;
  --ion-color-success-rgb: 67, 170, 76;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #3dba3f;
  --ion-color-success-tint: #58ad4b;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #414141;
  --ion-color-dark-rgb: 89, 89, 89;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #52423a;
  --ion-color-dark-tint: #6c5f59;
  --ion-color-dark-gray: #424242;
  --ion-color-gray-label: #5A5A5D;
  --ion-color-black-label: #050708;

  
  

  /** medium **/
  --ion-color-medium: #b7b7b7;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-darker-tint: #58585A;
  

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-off-white:#F6F7F8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** theme-green **/
  --ion-color-theme-green: #595959;
  --ion-color-theme-green-rgb: 89, 89, 89;
  --ion-color-theme-green-contrast: #ffffff;
  --ion-color-theme-green-contrast-rgb: 255, 255, 255;
  --ion-color-theme-green-shade: #4e4e4e;
  --ion-color-theme-green-tint: #6a6a6a;

  /** theme-orange **/
  --ion-color-orange: #e0633c;
  --ion-color-orange-rgb: 224, 99, 60;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #c55735;
  --ion-color-orange-tint: #e37350;

  --ion-color-transperent: #00000000;
  --ion-color-black: #000000;
  --ion-color-black-text: #343433;
  --ion-color-gray-text: #424443;
  
}
.ion-color-theme-green {
  --ion-color-base: var(--ion-color-theme-green);
  --ion-color-base-rgb: var(--ion-color-theme-green-rgb);
  --ion-color-contrast: var(--ion-color-theme-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-theme-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-theme-green-shade);
  --ion-color-tint: var(--ion-color-theme-green-tint);
}
.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

ion-rating {
  ion-button {
    --color: var(--ion-color-theme-green) !important;
    --color-filled: var(--ion-color-theme-green) !important;
    --color-focused: var(--ion-color-theme-green);
    --color-activated: var(--ion-color-theme-green);

    &.filled {
      --color: var(--ion-color-orange) !important;
      --color-filled: var(--ion-color-orange) !important;
      --color-focused: var(--ion-color-orange);
      --color-activated: var(--ion-color-orange);

      ion-icon {
        color: var(--ion-color-warning);
      }
    }
  }
}